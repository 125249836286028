<template>
  <v-navigation-drawer class="white elevation-22" v-model="drawer" app>
    <template v-slot:prepend>
      <v-img :src="drawerTopImage" max-height="70" contain />
    </template>
    <v-list class="pt-4">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.link"
        exact
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block class="purple white--text" @click="logout"> Logout </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["showDrawer"],
  data() {
    return {
      drawerTopImage: require("@/assets/logo.png"),
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", link: "/dashboard" },

        {
          link :"/dashboard/manage_services",
          title: "services", 
          icon: "mdi-pen",
        },
        {
          link :"/dashboard/manage_orders",
          title: "Orders", 
          icon: "mdi-pen",
        },
      ],
      drawer: false,
    };
  },
  watch: {
    showDrawer(val) {
      this.drawer = val;
    },
    drawer(val) {
      this.$emit("changeDrawer", val);
    },
  },
  methods: {
    logout() {
      var that = this;
      this.$fb
        .auth()
        .signOut()
        .then(() => {
          that.$router.push("/");
          this.$store.commit("setIsFromLogin", true);
        });
    },
  },
};
</script>